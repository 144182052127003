import React from 'react'
import { theme } from '../utils'
import styled from '@emotion/styled'
import { useTranslation } from 'react-i18next'
import { TicketPosition, QueueStoreStatus } from '../types'

export default function QueueTicket({
  ticket,
  queueStoreStatus,
}: {
  ticket: TicketPosition
  queueStoreStatus: QueueStoreStatus
}) {
  const { t } = useTranslation()
  return (
    <Ticket show={ticket}>
      <TicketNumber queueStoreStatus={queueStoreStatus}>
        {ticket.label}
      </TicketNumber>
      <TicketAction>{t('pleaseEnter')}</TicketAction>
    </Ticket>
  )
}

// The Ticket opacity should hide the "Ticket" when the queue is idle.
// We want the Queue Info to always be aligned to the bottom
// And the Header to always be at the top.

const {
  texts: { XLtext },
  others: { radius },
  spacing: { padding },
} = theme

// Ticket
const Ticket = styled.div`
  margin: 0 ${padding}px;
  display: flex;
  background: #fff;
  color: #000;
  border-radius: ${radius}px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  opacity: ${(props: any) => (props.show ? '1' : '0')};
  height: 100%;
  @media screen and (min-aspect-ratio: 4/3) {
    grid-row: 1 / span 2;
    grid-column: 2;
    margin: 0;
    z-index: 99;
  }
` as any

const TicketNumber = styled.span<{ queueStoreStatus: QueueStoreStatus }>`
  font-size: 145px;
  font-weight: bold;
  margin: auto auto 0 auto;

  @media screen and (min-width: 1920px) {
    font-size: 220px;
  }

  @media screen and (orientation: portrait) {
    margin-top: ${({ queueStoreStatus }) =>
      queueStoreStatus === 'danger' ? 40 : 0}px;
    font-size: ${({ queueStoreStatus }) =>
      queueStoreStatus === 'danger' ? 125 : 120}px;
  }
`
const TicketAction = styled.span`
  font-size: ${XLtext}px;
  margin: 0px auto auto auto;

  @media screen and (orientation: portrait) {
    font-size: 35px;
  }
`
