import { Queue, Occupancy } from '../types'
import * as signalR from '@microsoft/signalr'
import { v4 as uuidv4 } from 'uuid'
import { makeRetryableFetch } from '../utils'

type FetchQueueConfig = {
  queueEndpoint: string
  queueId: string
  organization: string
}

async function fetchQueue({
  queueEndpoint,
  queueId,
  organization,
}: FetchQueueConfig): Promise<Queue> {
  const URL = `${queueEndpoint}/organizations/${organization}/queues/${queueId}`
  try {
    const response = await fetch(URL)
    if (!response.ok) {
      throw new Error(
        `Network response was not ok in "fetchQueue". Status text: ${response.statusText}`
      )
    }
    return await response.json()
  } catch (err) {
    throw new Error(
      `There has been a problem with your "fetchQueue" operation: ${err.message}`
    )
  }
}

async function fetchOccupancy({
  queueEndpoint,
  queueId,
  organization,
}: FetchQueueConfig): Promise<Occupancy> {
  const URL = `${queueEndpoint}/organizations/${organization}/queues/${queueId}/occupancy`
  try {
    const response = await fetch(URL)
    if (!response.ok) {
      throw new Error(
        `Network response was not ok in "fetchOccupancy". Status text: ${response.statusText}`
      )
    }
    return await response.json()
  } catch (err) {
    throw new Error(
      `There has been a problem with your "fetchOccupancy" operation: ${err.message}`
    )
  }
}

type HubMethodName = 'queue-update' | 'position-update' | 'occupancy-update'

type SubscriptionQueueConfig = {
  queueEndpoint: string
  queueId: string
  organization: string
}

type SubscriptionToQueueService = {
  subscribe(
    hubMethodName: HubMethodName,
    subscriber: (message: any) => void
  ): void
  /**
    Remove all the handlers on the provided `hubMethod`.
   * */
  cancel(hubMethodName: HubMethodName): void
  /**
   * This would stop the connection of the subscribtion. Returns a promise.
   * */
  stop(): Promise<void>
}

function startSubscriptionToQueueService(
  url: string
): SubscriptionToQueueService {
  let hubConnectionBuilder = new signalR.HubConnectionBuilder()
    .withUrl(url)
    // Default configuration for reconnecting is that it will have 4 attempts
    // in retrying. The delay for retrying is 0, 2, 10, 30 seconds respectively.
    // - https://docs.microsoft.com/en-us/aspnet/core/signalr/javascript-client?view=aspnetcore-3.1#reconnect-clients
    .withAutomaticReconnect()

  if (process.env.NODE_ENV !== 'production') {
    hubConnectionBuilder = hubConnectionBuilder.configureLogging(
      signalR.LogLevel.Information
    )
  }

  const hubConnection = hubConnectionBuilder.build()

  hubConnection.onreconnecting((err) => {
    console.log(
      `Queue service connection lost due to error ${err}. Reconnecting.`
    )
  })

  hubConnection.onreconnected(() => {
    console.log('Queue service connection reestablished.')
  })

  hubConnection.onclose((err) => {
    console.error(
      `Queue service connection closed due to error ${err}. Try refreshing this page to restart the connection.`
    )
  })

  // Note: This would attempt a retry if the inital kick-off
  // trigger an error.
  const retryableSubscriptionToQueue = makeRetryableFetch(() =>
    hubConnection.start()
  )
  console.log('Queue service connection is established')
  retryableSubscriptionToQueue.promise.catch((err) => {
    throw err
  })

  return {
    subscribe(hubMethodName, subscriber) {
      hubConnection.on(hubMethodName, (message: any) => {
        subscriber(JSON.parse(message))
      })
    },
    cancel(hubMethodName) {
      hubConnection.off(hubMethodName)
    },
    async stop() {
      return await hubConnection.stop()
    },
  }
}

const subscriptionToQueueServiceMap = new Map<
  string,
  SubscriptionToQueueService
>()
/**
 * This would return subscription to the queue service. Use this if you
 * want to create socket to available methods from the server to listen
 * for the notification update.
 * If the passed `queueEndpoint` has already subscription instance, we will
 * return the existing subscription. If not yet exist, we will create new
 * instance. In this way, we can avoid creating another instance for the subscribing URL and just use the existing one.
 * */
function createSubscriptionToQueueService({
  queueEndpoint,
  organization,
  queueId,
}: SubscriptionQueueConfig) {
  const userId = uuidv4()
  const url = `${queueEndpoint}/organizations/${organization}/queues/${queueId}`
  const urlWithUserId = `${url}?userId=${userId}`

  // Don't include the userId because in every invoke, this will create new unique Id which
  // reject our caching strat. Only check without query string.
  if (!subscriptionToQueueServiceMap.has(url)) {
    const subscription = startSubscriptionToQueueService(urlWithUserId)
    subscriptionToQueueServiceMap.set(url, subscription)
    return subscription
  }
  return subscriptionToQueueServiceMap.get(url) as SubscriptionToQueueService
}

export { fetchQueue, fetchOccupancy, createSubscriptionToQueueService }
