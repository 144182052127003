import React from 'react'
import { QueueStoreStatus, Queue, TicketPosition, Occupancy } from '../types'
import {
  fetchQueue,
  fetchOccupancy,
  createSubscriptionToQueueService,
} from './api'
import { makeRetryableFetch, makeCancelablePromise } from '../utils'
import { useSettings } from '../Settings'
// Layouts
import LayoutWithoutQueue from '../LayoutWithoutQueue'
import LayoutWithQueue from '../LayoutWithQueue'
import AlternativeLayoutWithoutQueue from '../LayoutWithQueueV2'

type LoadAction = {
  type: 'LOAD_QUEUE'
}

type SuccessAction = {
  type: 'SUCCESS_QUEUE'
  payload: Queue
}

type ErrorAction = {
  type: 'ERROR_QUEUE'
  payload: Error
}

function queueReducer(
  state: { pending: boolean; data: Queue | null; error: Error | null },
  action: LoadAction | SuccessAction | ErrorAction
) {
  switch (action.type) {
    case 'LOAD_QUEUE': {
      return {
        ...state,
        pending: true,
        error: null,
      }
    }
    case 'SUCCESS_QUEUE': {
      return {
        ...state,
        pending: false,
        data: action.payload,
      }
    }
    case 'ERROR_QUEUE': {
      return {
        ...state,
        pending: false,
        error: action.payload,
      }
    }
    default: {
      // Just need to show this in dev and test. not in production.
      if (process.env.NODE_ENV !== 'production') {
        const err = new Error(
          'Processing the `queueReducer` triggers an error. Check the provided `action`.'
        )
        throw err
      }
      return state
    }
  }
}

const initQueue = {
  pending: false,
  data: null,
  error: null,
}

type LoadOccupancyAction = {
  type: 'LOAD_OCCUPANCY'
}

type OccupancySuccessAction = {
  type: 'SUCCESS_OCCUPANCY'
  payload: Occupancy
}

type OccupancyErrorAction = {
  type: 'ERROR_OCCUPANCY'
  payload: Error
}

function occupancyReducer(
  state: { pending: boolean; data: Occupancy | null; error: Error | null },
  action: LoadOccupancyAction | OccupancySuccessAction | OccupancyErrorAction
) {
  switch (action.type) {
    case 'LOAD_OCCUPANCY': {
      return {
        ...state,
        pending: true,
        error: null,
      }
    }
    case 'SUCCESS_OCCUPANCY': {
      return {
        ...state,
        pending: false,
        data: action.payload,
      }
    }
    case 'ERROR_OCCUPANCY': {
      return {
        ...state,
        pending: false,
        error: action.payload,
      }
    }
    default: {
      // Just need to show this in dev and test. not in production.
      if (process.env.NODE_ENV !== 'production') {
        const err = new Error(
          'Processing the `occupancyReducer` triggers an error. Check the provided `action`.'
        )
        throw err
      }
      return state
    }
  }
}

const initOccupancy = {
  pending: false,
  data: null,
  error: null,
}

// This would return the number of people in store and queue, and etc.
function createOtherDetailsFromQueue(queue: Queue) {
  const { positions } = queue

  // Aggregate the tickets based on the ticket status.
  const aggregateTickets = positions.reduce((acc, value) => {
    if (!Reflect.has(acc, value.status)) {
      return {
        ...acc,
        [value.status]: [value],
      }
    }
    const currentTicketItems = acc[value.status]
    return {
      ...acc,
      [value.status]: currentTicketItems.concat(value),
    }
  }, {} as Record<string, TicketPosition[]>)

  return {
    getTheNumberOfPeopleInQueue() {
      const tickets = aggregateTickets['pending']
      return tickets?.length ?? 0
    },
    getTheLatestTicket() {
      const tickets = aggregateTickets['notified']
      if (!tickets) {
        return
      }
      // First, shallow copy the tickets to avoid mutating the original array.
      // sort the tickets based on the `createdAt` date. We gonna sort it descending.
      const sortedTickets = tickets.slice().sort((a, b) => {
        const dateA = new Date(a.createdAt)
        const dateB = new Date(b.createdAt)
        return dateB.getTime() - dateA.getTime()
      })
      return sortedTickets[0] as TicketPosition // return the head.
    },
  }
}

/**
 * https://css-tricks.com/the-trick-to-viewport-units-on-mobile/
 */
function __BEWARE_IOS_SAFARI_HACK_DO_NOT_USE_ANYWHERE_addVhGlobalStylesToTheRoot() {
  // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
  let vh = window.innerHeight * 0.01
  // Then we set the value in the --vh custom property to the root of the document
  document.documentElement.style.setProperty('--vh', `${vh}px`)
}

__BEWARE_IOS_SAFARI_HACK_DO_NOT_USE_ANYWHERE_addVhGlobalStylesToTheRoot()

export default function App() {
  // data need from the provider.
  const [peopleOverCapacity, setPeopleOverCapacity] = React.useState(0)
  const [queue, dispatchQueue] = React.useReducer(queueReducer, initQueue)
  const [occupancy, dispatchOccupancy] = React.useReducer(
    occupancyReducer,
    initOccupancy
  )

  const settings = useSettings()

  // This will create the other details derive from the queue data.
  const otherDetailsFromQueue = React.useMemo(() => {
    if (queue.data) {
      const queueUtils = createOtherDetailsFromQueue(queue.data)
      return {
        peopleInQueue: queueUtils.getTheNumberOfPeopleInQueue(),
        latestTicket: queueUtils.getTheLatestTicket(),
      }
    }
    return {
      peopleInStore: 0,
      peopleInQueue: 0,
    }
  }, [queue.data])

  const queueStoreStatus = React.useMemo(() => {
    const peopleInStore = occupancy?.data?.counter ?? 0
    if (queue.data) {
      const { storeCapacity } = queue.data
      const warningStoreCapacity =
        settings.warningStoreCapacity ?? storeCapacity - 1
      if (
        peopleInStore >= warningStoreCapacity &&
        peopleInStore < storeCapacity
      ) {
        return QueueStoreStatus.warning
      }
      if (peopleInStore >= storeCapacity) {
        return QueueStoreStatus.danger
      }
    }
    return QueueStoreStatus.success
  }, [queue.data, settings.warningStoreCapacity, occupancy])

  React.useEffect(
    function loadQueue() {
      const { queueId, organization, queueEndpoint } = settings
      dispatchQueue({ type: 'LOAD_QUEUE' })
      // To integrate a retrying logic if there is connection error.
      const retryableFetchQueRequest = makeRetryableFetch(() =>
        fetchQueue({ queueEndpoint, queueId, organization })
      )
      // To avoid memory leak in data fetching..
      const cancelableFetchQueue = makeCancelablePromise(
        retryableFetchQueRequest.promise
      )
      cancelableFetchQueue.promise
        .then((data) => {
          if (data) {
            dispatchQueue({ type: 'SUCCESS_QUEUE', payload: data })
          }
        })
        .catch((err) => {
          dispatchQueue({ type: 'ERROR_QUEUE', payload: err })
        })

      return () => {
        cancelableFetchQueue.cancel()
        retryableFetchQueRequest.cancel()
      }
    },
    [settings]
  )

  React.useEffect(
    function loadOccupancy() {
      const { queueId, organization, queueEndpoint } = settings
      dispatchOccupancy({ type: 'LOAD_OCCUPANCY' })
      const retryableFetchQueRequest = makeRetryableFetch(() =>
        fetchOccupancy({ queueEndpoint, queueId, organization })
      )
      const cancelableFetchQueue = makeCancelablePromise(
        retryableFetchQueRequest.promise
      )
      cancelableFetchQueue.promise
        .then((data) => {
          if (data) {
            dispatchOccupancy({ type: 'SUCCESS_OCCUPANCY', payload: data })
          }
        })
        .catch((err) => {
          dispatchOccupancy({ type: 'ERROR_OCCUPANCY', payload: err })
        })

      return () => {
        cancelableFetchQueue.cancel()
        retryableFetchQueRequest.cancel()
      }
    },
    [settings]
  )

  React.useEffect(
    function subscribeToloadQueue() {
      const { queueId, organization, queueEndpoint } = settings

      const subscription = createSubscriptionToQueueService({
        queueId,
        organization,
        queueEndpoint,
      })

      const hubMethodName = 'queue-update'

      // We can catch the thrown error though the Promise returned by the `subscribe`.
      subscription.subscribe(hubMethodName, ({ queue }: { queue: Queue }) => {
        if (queue) {
          dispatchQueue({ type: 'SUCCESS_QUEUE', payload: queue })
        }
      })

      return () => {
        // Subscription will remove all handlers and also stop the connection.
        // It returns a promise which we can check if the connection is properly stop.
        subscription.cancel(hubMethodName)
      }
    },
    [settings]
  )

  React.useEffect(
    function subscribeToloadOccupancy() {
      const { queueId, organization, queueEndpoint } = settings

      const subscription = createSubscriptionToQueueService({
        queueId,
        organization,
        queueEndpoint,
      })

      const hubMethodName = 'occupancy-update'

      // We can catch the thrown error though the Promise returned by the `subscribe`.
      subscription.subscribe(
        hubMethodName,
        ({ occupancy }: { occupancy: Occupancy }) => {
          if (occupancy) {
            dispatchOccupancy({ type: 'SUCCESS_OCCUPANCY', payload: occupancy })
          }
        }
      )

      return () => {
        // Subscription will remove all handlers and also stop the connection.
        // It returns a promise which we can check if the connection is properly stop.
        subscription.cancel(hubMethodName)
      }
    },
    [settings]
  )

  const { storeCapacity } = queue.data ?? { storeCapacity: 0 }

  const occupancyCount = occupancy?.data?.counter ?? 0
  const currentPeopleOverCapacity = occupancyCount - storeCapacity

  if (peopleOverCapacity !== currentPeopleOverCapacity) {
    setPeopleOverCapacity(currentPeopleOverCapacity)
  }

  React.useEffect(function addingGlobalStylesFromResizeEvent() {
    function handleResizeChange() {
      __BEWARE_IOS_SAFARI_HACK_DO_NOT_USE_ANYWHERE_addVhGlobalStylesToTheRoot()
    }

    window.addEventListener('resize', handleResizeChange)

    return () => {
      window.removeEventListener('resize', handleResizeChange)
    }
  }, [])

  if (!queue.data) {
    return <div>Loading queue...</div>
  }

  const peopleInStore = storeCapacity
    ? Math.min(storeCapacity, occupancyCount)
    : occupancyCount

  if (settings.enableQueue) {
    return (
      <LayoutWithQueue
        queueStoreStatus={queueStoreStatus}
        storeCapacity={storeCapacity}
        peopleInStore={peopleInStore}
        latestTicket={otherDetailsFromQueue.latestTicket}
        peopleInQueue={otherDetailsFromQueue.peopleInQueue}
      />
    )
  }

  if (settings.enableAlternativeLayoutWithoutQueue) {
    return (
      <AlternativeLayoutWithoutQueue
        queueStoreStatus={queueStoreStatus}
        storeCapacity={storeCapacity}
        peopleInStore={peopleInStore}
      />
    )
  }

  return (
    <LayoutWithoutQueue
      queueStoreStatus={queueStoreStatus}
      storeCapacity={storeCapacity}
      peopleInStore={peopleInStore}
    />
  )
}
