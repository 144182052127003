// We create enum to avoid some foreign values.
// In this way, we assure that the given status will have
// its associated color.
export enum QueueStoreStatus {
  success = 'success',
  warning = 'warning',
  danger = 'danger',
}

export type Position = {
  id: string
  // fulfilled = in store, notified = called in (show ticket), pending = people in queue
  status: 'notified' | 'pending' | 'fulfilled'
  priority: number
  label: string
  createdAt: string
  queue: string
}

export type TicketPosition = {
  id: string
  // fulfilled = in store, notified = called in (show ticket), pending = people in queue
  status: 'notified' | 'pending' | 'fulfilled'
  priority: number
  label: string
  createdAt: string
  queue: string
}

export type Queue = {
  id: string
  organization: string
  storeCapacity: number
  positions: Position[]
}

export type Occupancy = {
  id: string
  organization: string
  counter: number
}

export type QueueStoreInfo = {
  queueStoreStatus: QueueStoreStatus
  peopleInStore: number
  storeCapacity?: number
}
