import React from 'react'

export type SettingsProps = {
  defaultLanguage: string
  queueId: string
  queueEndpoint: string
  enableQueue: boolean
  organization: string
  warningStoreCapacity?: number
  phoneNumber?: string
  textMessage?: string
  qrCodeLink?: string
  useAudio?: boolean
  logo?: string
  enableAlternativeLayoutWithoutQueue: boolean
  enableOccupancy: boolean
  enableTotalOccupancy: boolean
}

const SettingsContext = React.createContext<SettingsProps | null>(null)

const SettingsProvider = SettingsContext.Provider

function useSettings() {
  const ctx = React.useContext(SettingsContext)
  if (!ctx) {
    throw new Error(
      `Error caught while consuming Settings context. Make sure you wrap the Component inside the "SettingsProvider".`
    )
  }
  return ctx
}

export { SettingsProvider, useSettings }
