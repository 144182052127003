import React from 'react'
import styled from '@emotion/styled'
import { theme } from '../utils'
import {
  Slash as SlashIcon,
  AlertTriangle as AlertTriangleIcon,
} from 'react-feather'
import { AnimatedArrowUp } from '../components'
import { useSettings } from '../Settings'
import { useTranslation, Trans } from 'react-i18next'
import { QueueStoreStatus, QueueStoreInfo } from '../types'

const { others, colors, texts } = theme

function SuccessQueueMessage() {
  const { t } = useTranslation()
  return (
    <QueueMessage>
      <Headline>{t('storeIsNotFullCoop.greetings')}</Headline>
      <Center>
        <AnimatedArrowUp size={others.bigIconSize} color="#fff" />
      </Center>
      <Headline>
        <Trans i18nKey="storeIsNotFullCoop.welcome" />
      </Headline>
    </QueueMessage>
  )
}

function DangerQueueMessage() {
  const { t } = useTranslation()
  return (
    <QueueMessage>
      <Headline>{t('storeIsFullCoop.greetings')}</Headline>
      <Center>
        <SlashIcon size={others.bigIconSize - 50} />
      </Center>
      <Headline>
        <Trans i18nKey="storeIsFullCoop.storeIsFull" />
      </Headline>
    </QueueMessage>
  )
}

export default function CoopLayout({
  queueStoreStatus,
  peopleInStore,
  storeCapacity,
}: QueueStoreInfo) {
  const { t } = useTranslation()
  const settings = useSettings()
  return (
    <Container bgColor={colors[queueStoreStatus]}>
      <Center>
        {queueStoreStatus === QueueStoreStatus.danger ? (
          <DangerQueueMessage />
        ) : (
          <SuccessQueueMessage />
        )}
      </Center>
      <Card>
        {Boolean(settings.logo) && <Logo src={settings.logo} />}
        <QueueDetails>
          <img
            src="https://gridmedia.blob.core.windows.net/media/5eb447b76d1ead7b9a1d3804/036ca8e0-93c3-11ea-908f-d1753138a784"
            alt="queue details img"
          />
          <QueueInstructions>
            <AlertTriangleIcon size={80} /> {t('queueReminderCoop')}
          </QueueInstructions>
        </QueueDetails>
        {settings.enableOccupancy && (
          <Paragraph>
            {!Boolean(storeCapacity) || !settings.enableTotalOccupancy
              ? t('peopleInStoreWithoutStoreCapacity', { peopleInStore })
              : t('peopleInStore', { peopleInStore, storeCapacity })}
          </Paragraph>
        )}
      </Card>
    </Container>
  )
}

const Container = styled.div<{ bgColor: string }>`
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  color: #ffffff;
  background-color: ${(props) => props.bgColor};
  display: grid;
  grid-template-columns: 54% 46%;

  @media (orientation: portrait) {
    grid-template-columns: 1fr;
  }
`

const Headline = styled.h1`
  font-size: ${texts.XLtext}px;
  font-weight: 800;
`

const Paragraph = styled.p`
  font-size: 25px;
  font-weight: normal;
  text-align: center;
  padding-left: 24px;
  margin: auto 0;
`

const Center = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  position: relative;
  z-index: 2;
`

const QueueMessage = styled.section`
  display: grid;
  grid-row-gap: 10px;
  text-align: center;
`

const Card = styled.div`
  position: relative;
  overflow: hidden;
  display: grid;
  grid-gap: 16px;
  padding: 58px;

  &:after {
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    left: 0;
    border-radius: 50%;
    width: 150%;
    height: 150%;
    background-color: rgba(255, 255, 255, 0.16);
    z-index: 1;
    content: '';
  }
`

const Logo = styled.img`
  height: 180px;
  margin: auto;
`

const QueueDetails = styled.section`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const QueueInstructions = styled.span`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 104px;
  font-size: 28px;
  font-weight: 800;
  transform: translate(-50%, -50%);
  z-index: 2;
  text-align: center;
`
