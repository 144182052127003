import React from 'react'
import styled from '@emotion/styled'
import { keyframes } from '@emotion/core'
import { QrCode } from '@ombori/gdm-react-qr-run'
import { ArrowRight } from 'react-feather'
import { theme } from '../utils'
import { AnimatedArrowUp } from '../components'
import { useSettings } from '../Settings'
import { useTranslation, Trans } from 'react-i18next'
import { TicketPosition, QueueStoreStatus } from '../types'

export default function QueueHeader({
  queueStoreStatus,
  ticket,
}: {
  queueStoreStatus: QueueStoreStatus
  ticket?: TicketPosition
}) {
  const { t } = useTranslation()

  const { textMessage, phoneNumber, qrCodeLink } = useSettings()

  const hasPhoneNumber = React.useCallback(
    () => Boolean(phoneNumber) && Boolean(textMessage),
    [phoneNumber, textMessage]
  )

  const scanQrCodeAndPhoneEl = React.useMemo(() => {
    return (
      <CTA>
        {hasPhoneNumber() ? (
          <Trans
            i18nKey="scanQrWithPhoneNumber"
            values={{ textMessage, phoneNumber }}
          />
        ) : (
          <Trans i18nKey="scanQr" />
        )}
      </CTA>
    )
  }, [hasPhoneNumber, phoneNumber, textMessage])

  if (queueStoreStatus !== QueueStoreStatus.danger) {
    return (
      <Header ticket={ticket}>
        <HeaderTitle>
          {t('storeIsNotFull.greetings')} {t('storeIsNotFull.welcome')}
        </HeaderTitle>
        <ArrowUpContainer>
          <AnimatedArrowUp size={bigIconSize} />
        </ArrowUpContainer>
      </Header>
    )
  }

  return (
    <>
      <QRSection>
        <HeaderTitle>{t('joinTheQueue')}</HeaderTitle>
        <QrPanel>
          {scanQrCodeAndPhoneEl}
          <Arrow>
            <ArrowRight color="#fff" size={80} />
          </Arrow>
          <QrWrapper>
            {qrCodeLink && <QrCode value={qrCodeLink} size={185} />}
          </QrWrapper>
        </QrPanel>
      </QRSection>
      <LandscapeQR>
        <LandscapeQrWrapper>
          {qrCodeLink && <QrCode value={qrCodeLink} size={245} />}
        </LandscapeQrWrapper>
        {scanQrCodeAndPhoneEl}
      </LandscapeQR>
    </>
  )
}

// Text sizes
const {
  texts: { XLtext, Ltext },
  spacing: { padding },
  others: { radius, bigIconSize },
} = theme
const Sradius = radius / 2

// Animations
const move = keyframes`
  0% {
    transform: translate(0,0);
  }
  50% {
    transform: translate(-100%,0);
  }
`

// Header
const Header = styled.div<{ ticket?: TicketPosition }>`
  @media screen and (min-aspect-ratio: 4/3) {
    grid-row: 1 / span 2;
    grid-column: 1;
    flex: 1;
    padding-top: 20%;
  }
  @media screen and (max-aspect-ratio: 3/4) {
    grid-row: 2 / span 2;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: ${({ ticket }) => (ticket ? '20px 0' : '100px 0')};
  }
`

const HeaderTitle = styled.h1`
  text-align: center;
  font-size: ${XLtext}px;
  font-weight: bold;
  color: #fff;
  max-width: 70%;
  margin: 0 auto;
  margin-bottom: 32px;

  @media screen and (orientation: portrait) {
    font-size: 50px;
  }
}
`

// QR Section
const QRSection = styled.div`
  @media screen and (min-aspect-ratio: 4/3) {
    display: none;
  }
  @media screen and (max-aspect-ratio: 3/4) {
    display: inherit;
  }
`

const QrPanel = styled.div`
  display: grid;
  grid-gap: 37px;
  grid-template-columns: 1fr 94px 260px;
  padding: 0 ${padding}px;
  align-items: center;
`

const CTA = styled.p`
  margin: auto 0;
  font-size: ${Ltext}px;
  line-height: 1.4;
`

const Arrow = styled.div`
  width: 80px;
  animation: ${move};
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-timing-function: ease;
`
const QrWrapper = styled.div`
  width: 232px;
  height: 232px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  border-radius: ${Sradius}px;
`

// Landscape QR section
// QR Section
const LandscapeQR = styled.div`
  grid-gap: 24px;
  grid-template-rows: 360px auto auto;
  align-items: center;
  justify-content: center;
  margin: auto 0;
  text-align: center;

  @media screen and (min-aspect-ratio: 4/3) {
    display: grid;
  }
  @media screen and (max-aspect-ratio: 3/4) {
    display: none;
  }

  @media screen and (orientation: portrait) {
    display: none;
  }

  h1 {
    margin-bottom: 0;
  }
`

const LandscapeQrWrapper = styled(QrWrapper)`
  width: 305px;
  height: 305px;
  margin: 0 auto;
`

const ArrowUpContainer = styled.div`
  @media screen and (orientation: portrait) {
    & svg {
      width: 145px;
      height: 145px;
    }
  }
`
