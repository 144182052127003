import React from 'react'
import styled from '@emotion/styled'
import { keyframes } from '@emotion/core'
import { ArrowUp } from 'react-feather'
import { theme } from '../../utils'

const bounce = keyframes`
  0% {
    transform: translate(0,0);
  }
  50% {
    transform: translate(0,25%);
  }
`

const BigArrow = styled.div<{ color?: string }>`
  animation: ${bounce};
  animation-duration: 1.5s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  /* color: ${theme.colors.success}; */
  color: ${(props) => props.color};
  text-align: center;
`

export default function AnimatedArrowUp({
  size,
  color,
}: {
  size: number
  color?: string
}) {
  console.log(color)
  return (
    <BigArrow color={color ? theme.colors.success : color}>
      <ArrowUp color={color} size={size} />
    </BigArrow>
  )
}
