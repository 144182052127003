import React from 'react'
import styled from '@emotion/styled'
import { Users, AlertTriangle, CheckCircle } from 'react-feather'
import QueueHeader from './QueueHeader'
import QueueTicket from './QueueTicket'
import { theme } from '../utils'
import { useSettings } from '../Settings'
import { useTranslation } from 'react-i18next'
import { QueueStoreStatus, TicketPosition, QueueStoreInfo } from '../types'

export default function LayoutWithQueue({
  queueStoreStatus,
  latestTicket,
  peopleInQueue,
  peopleInStore,
  storeCapacity,
}: {
  latestTicket?: TicketPosition
  peopleInQueue: number
} & QueueStoreInfo) {
  const settings = useSettings()
  const currentQueueStatusColor = colors[queueStoreStatus]
  const { t } = useTranslation()

  return (
    <Container queueStoreStatus={queueStoreStatus}>
      <QueueHeaderContainer>
        {Boolean(settings.logo) && <Logo src={settings.logo} />}
        <QueueHeader
          ticket={latestTicket}
          queueStoreStatus={queueStoreStatus}
        />
      </QueueHeaderContainer>
      {latestTicket && (
        <QueueTicket
          ticket={latestTicket}
          queueStoreStatus={queueStoreStatus}
        />
      )}
      <QueueInfo>
        <QueuePanel>
          <PanelIcon>
            <Users />
          </PanelIcon>
          <PanelContent>
            <PanelTitle>{t('peopleInQueue', { peopleInQueue })}</PanelTitle>
          </PanelContent>
        </QueuePanel>
        <QueuePanel background={currentQueueStatusColor}>
          <PanelIcon background={currentQueueStatusColor}>
            {queueStoreStatus !== QueueStoreStatus.success ? (
              <AlertTriangle />
            ) : (
              <CheckCircle />
            )}
          </PanelIcon>
          <PanelContent>
            {settings.enableOccupancy && (
              <PanelTitle>
                {!Boolean(storeCapacity) || !settings.enableTotalOccupancy
                  ? t('peopleInStoreWithoutStoreCapacity', { peopleInStore })
                  : t('peopleInStore', { peopleInStore, storeCapacity })}
              </PanelTitle>
            )}
            <PanelText>{t('queueReminder')}</PanelText>
          </PanelContent>
        </QueuePanel>
      </QueueInfo>
    </Container>
  )
}

const {
  texts: { XLtext, text },
  spacing: { gap },
  colors,
} = theme

// Queue Info
const QueueInfo = styled.div`
  align-self: flex-end;
  @media screen and (min-aspect-ratio: 4/3) {
    grid-column: 2;
    grid-row: 1 / span 2;
    display: grid;
    grid-template-rows: 1fr 1fr;
    gap: 16px;
    height: 100%;
  }
`
const QueuePanel = styled.div<{ background: string }>`
  display: grid;
  gap: 8px;
  grid-template-columns: 112px 1fr;
  padding: 13px 40px;

  @media screen and (min-aspect-ratio: 4/3) {
    gap: 0;
    grid-template-columns: 1fr;
    grid-template-rows: auto auto;
    align-items: center;
    background: #1d1d1d;
    color: rgba(255, 255, 255, 0.8);
    border-radius: 16px;
  }

  @media (orientation: portrait) {
    color: ${(props: { background: string }) =>
      props.background === '#FFC107' ? '#000' : '#fff'};
    background: ${(props: { background: string }) =>
      props.background ? props.background : '#1d1d1d'};
  }
` as any

const PanelIcon = styled.div<any>`
  width: 112px;
  svg {
    width: 76px;
    height: 76px;
  }
  @media screen and (min-aspect-ratio: 4/3) {
    width: 112px;
    height: 112px;
    color: ${(props: { background: string }) =>
      props.background === '#FFC107' ? '#000' : '#fff'};
    background: ${(props: { background: string }) =>
      props.background ? props.background : '#1d1d1d'};
    border-radius: 112px;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      width: 88px;
      height: 88px;
    }
  }
` as any
const PanelContent = styled.div``
const PanelTitle = styled.span`
  font-size: ${XLtext}px;
  font-weight: bold;
  display: block;
  @media screen and (min-aspect-ratio: 4/3) {
    color: #fff;
  }
`
const PanelText = styled.span`
  display: block;
  font-size: ${text};
  margin-top: 16px;
`

// Container
const Container = styled.div<{ queueStoreStatus: QueueStoreStatus }>`
  position: relative;
  width: 100vw;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  display: grid;
  overflow: hidden;
  background: #000;
  color: rgba(255, 255, 255, 0.88);
  font-size: ${text}px;
  align-items: center;

  /* Landscape */
  @media screen and (min-aspect-ratio: 4/3) {
    grid-template-rows: 1fr;
    grid-template-columns: 62% 38%;
    padding: 16px;
    gap: 0;
  }

  /* Portrait */
  @media screen and (max-aspect-ratio: 3/4) {
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    padding: 19px 0 0 0;
    grid-template-rows: auto;
    grid-gap: ${({ queueStoreStatus }) =>
      queueStoreStatus === QueueStoreStatus.danger ? 22 : gap}px;
  }
`

const Logo = styled.img`
  margin: 0 auto;
  max-height: 88px;
  @media screen and (min-aspect-ratio: 4/3) {
    grid-row: 1;
    grid-column: 1;
  }
`

const QueueHeaderContainer = styled.div`
  text-align: center;

  @media (orientation: landscape) {
    height: 100%;
    display: flex;
    flex-direction: column;
  }
`
