import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import locales from "./locales";

i18n
  // https://react.i18next.com/guides/quick-start#configure-i-18-next
  .use(initReactI18next)
  // https://www.i18next.com/overview/configuration-options
  .init({
    resources: locales,
    lng: "en",
    debug: true,
    interpolation: { escapeValue: false }, // not needed for react as it escapes by default
  });

export default i18n;
