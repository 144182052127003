import React from 'react'
import App from '../App'
import { Global, css } from '@emotion/core'
import reset from 'emotion-minireset'
import { SettingsProvider } from '../Settings'
import queryString from 'query-string'
import 'pwacompat'

console.log(
  'sdfsadfsad',
  queryString.stringify({ a: 'http://localhost:7071/api' })
)

function getSettingsFromQuery() {
  // eslint-disable-next-line no-restricted-globals
  const { search } = location
  if (!search) {
    throw new Error(
      `The passed url doesn't have settings which are appended to the query parameters. You need to fill this parameters.`
    )
  }
  const parsedSettings = queryString.parse(search)
  // We gonna change some of the value of parsedSettings like string verssion boolean to its original type.
  const newSettings = Object.entries(parsedSettings).reduce(
    (acc, [key, value]) => {
      if (value === 'true' || value === 'false') {
        return {
          ...acc,
          [key]: value === 'true',
        }
      }
      return {
        ...acc,
        [key]: value,
      }
    },
    {}
  )

  return newSettings as any
}

const globalStyles = [
  reset,
  css`
    body {
      font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
        Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
        'Segoe UI Symbol';
      color: #000;
    }
  `,
]

function Root() {
  const settings = getSettingsFromQuery()
  return (
    <SettingsProvider value={settings}>
      <Global styles={globalStyles} />
      <App />
    </SettingsProvider>
  )
}

export default Root
