import * as serviceWorkers from './serviceWorker'

const theme = {
  texts: {
    XLtext: 50,
    Ltext: 30,
    text: 20
  },
  spacing: {
    gap: 64,
    padding: 96
  },
  colors: {
    success: '#2C9918',
    danger: '#E70D0D',
    warning: '#FFC107',
    dark: '#000000',
    midDark: '#1d1d1d'
  },
  others: {
    radius: 16,
    bigIconSize: 200
  }
}

/**
 * This would cancel the invocation of the `then(cb)` in the user-land
 * if the promise is cancelled. This is very useful for fetching data
 * then add a React setter inside `promise.then(cb)`. This would avoid setting
 * the state of a Component if the promise is already cancelled like
 * cancelling when the Component is unmounted. This is alternative for
 * `isMounted` variable solution.
 *
 * I favored for this solution than the `isMounted` because `isMounted`
 * flag can create unnecessary side-effect like adding setter and not
 * including it to the `isMounted` branch.
 *
 * NOTE: The Promise object is not trully cancelable. This is just
 * solution for preventing a memory leak.
 * */
function makeCancelablePromise<T>(promise: Promise<T>) {
  let hasCanceled = false

  const wrappedPromise = new Promise<T>((resolve, reject) => {
    promise.then(
      val => (hasCanceled ? reject({ isCanceled: true }) : resolve(val)),
      error => (hasCanceled ? reject({ isCanceled: true }) : reject(error))
    )
  })

  return {
    promise: wrappedPromise,
    cancel() {
      hasCanceled = true
    }
  }
}

// Endpoint use for "development".
function _dev_createEndpoint() {
  return process.env.NODE_ENV === 'development'
    ? 'http://localhost:7071/api'
    : 'https://ombori-queue-prod-uae.azure-api.net/api'
}

function sleep(ms: number) {
  return new Promise(res => setTimeout(() => res(), ms))
}

export type RetryableFetch<T> = {
  promise: Promise<T | undefined>
  cancel: () => void
}

/**
 * Wrapping a fetch request function. This would trigger retry attempt. Note that the type wrap by the Promise is the inferred type by typescript, e.g `Queue`,  or `undefined`. It will resolve to `undefined` if the
 * retrying is cancelled via invoking `cancel`.
 *
 * `retriesConfig` is an array of delay ms. E.g, in first attemp the delay
 * would be 0ms, then 2nd is 2s and so on and so forth. This is also the same
 * with `signalR` in handling retry logic.
 * */
function makeRetryableFetch<T>(
  fetchRequest: () => Promise<T>,
  retriesConfig = [0, 2000, 10000, 30000]
): RetryableFetch<T> {
  let retries = retriesConfig.length
  let hasCanceled = false

  async function retryFetch(): Promise<any> {
    try {
      return await fetchRequest()
    } catch (err) {
      console.log(
        `There has been a problem with your fetch operation. Try to reconnect...`
      )
      if (retries === 0) {
        const newErr = new Error(
          `There has been a problem with your fetch operation: ${err}.`
        )
        newErr.stack = err
        throw newErr
      }
      // reverse the config to pass the delay in ascending order.
      // invoke `.slice` to shallowly copy the array.
      const delay = retriesConfig.slice().reverse()[retries - 1]
      console.log(`Reconnecting after ${delay}ms.`)
      await sleep(delay)
      if (!hasCanceled) {
        // Decrement retries here to be able the recursion will break if it is equal to 0 before invoking again the function.
        retries--
        return await retryFetch()
      }
    }
  }

  return {
    promise: retryFetch(),
    cancel() {
      hasCanceled = true
    }
  }
}

export {
  makeCancelablePromise,
  theme,
  _dev_createEndpoint,
  sleep,
  makeRetryableFetch,
  serviceWorkers
}
