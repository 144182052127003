import React from 'react'
import styled from '@emotion/styled'
import { theme } from '../utils'
import {
  Slash as SlashIcon,
  AlertTriangle as AlertTriangleIcon,
  User as UserIcon,
  ArrowLeft as ArrowLeftIcon,
  ArrowRight as ArrowRightIcon,
} from 'react-feather'
import { AnimatedArrowUp } from '../components'
import { useSettings } from '../Settings'
import { useTranslation, Trans } from 'react-i18next'
import { QueueStoreStatus, QueueStoreInfo } from '../types'

const { texts, others, colors } = theme

const xtraSmallIconSize = 55
const smallIconSize = 65
const medIconSize = 130

function SuccessQueueMessage() {
  const { t } = useTranslation()
  return (
    <QueueMessage>
      <Center>
        <AnimatedArrowUp size={others.bigIconSize} />
      </Center>
      <Headline>{t('storeIsNotFull.greetings')}</Headline>
      <Headline>{t('storeIsNotFull.welcome')}</Headline>
    </QueueMessage>
  )
}

function DangerQueueMessage() {
  const { t } = useTranslation()
  return (
    <QueueMessage>
      <Center>
        <SlashIcon size={others.bigIconSize - 50} color={colors.danger} />
      </Center>
      <Headline>{t('storeIsFull.greetings')}</Headline>
      <Headline>
        <Trans i18nKey="storeIsFull.storeIsFull" />
      </Headline>
    </QueueMessage>
  )
}

export default function LayoutWithoutQueue({
  queueStoreStatus,
  peopleInStore,
  storeCapacity,
}: QueueStoreInfo) {
  const { t } = useTranslation()
  const settings = useSettings()
  return (
    <Container>
      <QueueInfo>
        {settings.logo && <Logo src={settings.logo} />}
        <Center>
          {queueStoreStatus === QueueStoreStatus.danger ? (
            <DangerQueueMessage />
          ) : (
            <SuccessQueueMessage />
          )}
        </Center>
      </QueueInfo>
      <Card>
        <Center style={{ padding: '0 80px' }}>
          <QueueDetails>
            <InStore>
              {settings.enableOccupancy && (
                <Headline>
                  {!Boolean(storeCapacity) || !settings.enableTotalOccupancy
                    ? t('peopleInStoreWithoutStoreCapacity', { peopleInStore })
                    : t('peopleInStore', { peopleInStore, storeCapacity })}
                </Headline>
              )}
              <Paragraph>{t('queueReminder')}</Paragraph>
            </InStore>
            <CircleContainer bgColor={colors[queueStoreStatus]}>
              <Center>
                <AlertTriangleIcon size={smallIconSize} />
              </Center>
            </CircleContainer>
            <Icons>
              <UserIcon size={medIconSize} />
              <ArrowIcons>
                <ArrowLeftIcon size={xtraSmallIconSize} />
                <ArrowRightIcon size={xtraSmallIconSize} />
              </ArrowIcons>
              <UserIcon size={medIconSize} />
            </Icons>
          </QueueDetails>
        </Center>
      </Card>
    </Container>
  )
}

const Container = styled.div`
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  color: #ffffff;
  background-color: ${colors.dark};
  padding: 16px;
  display: grid;
  grid-template-columns: 62% 38%;

  @media (orientation: portrait) {
    grid-template-columns: 1fr;
  }
`

const Headline = styled.h1`
  font-size: ${texts.XLtext}px;
  font-weight: 800;
  margin: 0 auto 5px auto;

  @media (orientation: portrait) {
    margin: 0 0 5px 0;
  }
`

const Paragraph = styled.p`
  font-size: ${texts.text}px;
  line-height: 1.5;
  margin: 0 auto;

  @media (orientation: landscape) {
    max-width: 313px;
  }
`

const QueueInfo = styled.div`
  display: flex;
  flex-direction: column;
`

const Center = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`

const QueueMessage = styled.section`
  display: grid;
  grid-row-gap: 20px;
  text-align: center;
`

const Card = styled.div`
  border-radius: ${others.radius}px;
  background-color: ${colors.midDark};
`

const QueueDetails = styled.section`
  display: grid;
  grid-row-gap: 30px;
  justify-items: center;
`

const InStore = styled.div`
  display: grid;
  grid-row-gap: 13px;
`

const Icons = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  align-items: center;
  justify-items: center;
`

const ArrowIcons = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 10px;
`

const CircleContainer = styled.div<{ bgColor: string }>`
  height: 118px;
  width: 118px;
  background-color: ${(props) => props.bgColor};
  border-radius: 50%;
`

const Logo = styled.img`
  margin: 0 auto;
  max-height: 120px;
  @media screen and (min-aspect-ratio: 4/3) {
    grid-row: 1;
    grid-column: 1;
  }
`
